@import 'styles/theme';

.VideoControls {
  display: flex;
  align-items: center;
  transition: opacity 0.4s ease-in-out;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  .playpausebutton {
    svg {
      padding: 10px;
      width: 10px;
      max-height: 10px;
    }
  }

  .muteunmutebutton {
    + .progressbar {
      margin-left: 10px;
    }

    svg {
      padding: 10px;
      width: 15px;
      max-height: 15px;
    }
  }

  .progressbar {
    flex: 1;
    padding: theme($spacings, 10) 0;
  }

  .fullscreenbutton {
    svg {
      padding: 10px;
      width: 12px;
      max-height: 12px;
    }
  }
}
