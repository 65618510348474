@import 'styles/theme';

$dotSize: 4px;

.Intensity {
  display: inline-flex;
  align-items: center;
  opacity: 0;
  transition: 0.4s opacity;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;

    .dot {
      opacity: 1;
    }
  }

  .dot {
    box-sizing: border-box;
    width: $dotSize;
    height: $dotSize;
    background-color: transparent;
    border: 1px solid theme($colors, 'dove-gray');
    border-radius: 50%;
    overflow: hidden;
    opacity: 0;
    transition: 0.4s opacity;

    &.active {
      border-color: theme($colors, 'black');
      background-color: theme($colors, 'black');
    }
  }

  span + span {
    margin-left: theme($spacings, 5);
  }
}
