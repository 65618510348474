@import 'styles/theme';

.CoverImage {
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-top: theme($spacings, 60);
    margin-bottom: theme($spacings, 60);
  }

  .imageContainer {
    overflow: hidden;
  }

  .title {
    position: relative;
    grid-column: 2 / -2;
    z-index: 1;
  }

  .banner {
    position: relative;
    grid-column: 1 / -1;

    .image {
      height: 625px;

      @include from('md') {
        height: stableVh(100);
      }
    }

    .cta {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: theme($spacings, 40);

      @include from('md') {
        margin-bottom: theme($spacings, 60);
      }
    }
  }

  &.last-slice {
    margin-bottom: 0;
  }

  &.titleSize {
    &-small {
      .title {
        + .banner {
          margin-top: -9px;

          @include from('md') {
            margin-top: -12px;
          }
        }
      }
    }

    &-big {
      .title {
        + .banner {
          // For the values below :
          // We have to pull the text up by half of their fontSizes
          // 18px : The min fontSize of the text is 45px, with a lineHeight of 0.8, so its (45 * 0.8) / 2 = 18
          // 60px : The max fontSize of the text is 150px, with a lineHeight of 0.8, so its (150 * 0.8) / 2 = 60
          @include applyFluidType(
            'margin-top',
            theme($screens, 'md'),
            theme($max, 'width-design'),
            18px,
            60px,
            -1
          );
        }
      }
    }
  }
}
