@import 'styles/theme';

$dotSize: 6px;

.ColorsProduct {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.3s opacity;
  opacity: 0;
  padding: theme($spacings, 3) 0;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
    opacity: 1;

    .dot {
      opacity: 1;
      transform: scale3d(1, 1, 1);

      @for $i from 1 through 15 {
        &:nth-child(#{$i}) {
          transition-delay: calc(#{$i - 1} * 0.15s);
        }
      }
    }
  }

  .dot {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    opacity: 0;
    transition: 0.5s opacity,
      0.3s transform cubic-bezier(0.655, 0.3, 0.35, 0.795);
    transform: scale3d(0.4, 0.4, 0.4);

    .dotContent {
      position: relative;
      display: inline-block;
      width: $dotSize;
      height: $dotSize;
      border-radius: 50%;
      box-sizing: border-box;

      &::before,
      &::after {
        content: '';
        z-index: -1;
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: theme($colors, 'black');
        border-radius: 50%;
        overflow: hidden;
        transform: translate3d(-50%, -50%, 0) scale3d(0.85, 0.85, 0.85);
        opacity: 0;
        transition: 0.2s opacity, 0.3s transform theme($easings, 'ease-in-out');
        will-change: opacity, transform;
      }

      &:after {
        z-index: -1;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        opacity: 0;
        background: theme($colors, 'white');
      }

      &:hover {
        &::before,
        &::after {
          opacity: 1;
          transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
        }
      }

      &.hasBorder {
        border: 1px solid theme($colors, 'alto');
      }

      &:last-child {
        margin-right: 2px;
      }
    }

    + .dot {
      z-index: 1;
      position: relative;
      margin-left: theme($spacings, 5);
    }

    + .rest {
      margin-left: theme($spacings, 5);
      line-height: 1;
    }
  }
}
