@import 'styles/theme';

.VariantsLabels {
  line-height: 1;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
    .label {
      opacity: 0.5;
      transform: translate3d(0, 0, 0);

      &.available {
        opacity: 1;
      }
    }
  }

  .label {
    display: inline-flex;
    opacity: 0;
    transition: 0.4s opacity;

    &:not(:last-child) {
      margin-right: theme($spacings, 10);
    }
  }
}
