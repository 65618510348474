@use 'sass:math';
@import 'styles/theme';

.VideoPlayerMock {
  position: relative;
  transition: opacity 0.4s ease-in-out;

  &.controls {
    cursor: pointer;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ratio {
    @include applyPercentRatios(math.div(2560, 1440));
  }

  .videoPlayer {
    position: absolute;
    top: 0;
    left: 0;
  }
}
