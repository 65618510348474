@use 'sass:math';
@import 'styles/theme';

.ProductCard {
  position: relative;
  display: block;
  user-select: none;

  @include switchDesktopMobileClasses();

  .images {
    overflow: hidden;
    position: relative;
    display: block;
  }

  .imageHover,
  .video {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .cardVideo {
    @include from('md') {
      max-width: unset;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
    }
  }

  .hideOnLargeScreen {
    width: 100%;
    overflow: hidden;
  }

  .slider {
    position: relative;
    width: 100%;
  }

  .sliderNavigation {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translate3d(0, -50%, 0);
    pointer-events: none;
    justify-content: space-between;
    box-sizing: border-box;

    & > * {
      pointer-events: auto;
    }

    .sliderIcon {
      width: 8px;
      height: auto;
      padding: {
        top: theme($spacings, 14);
        bottom: theme($spacings, 14);
        right: theme($spacings, 20);
        left: theme($spacings, 10);
      }

      &:first-of-type,
      &:last-of-type {
        padding-left: theme($spacings, 10);
      }
    }
  }

  .texts {
    margin-top: theme($spacings, 10);

    .topText {
      display: flex;

      .name {
        margin-right: theme($spacings, 5);

        @include from('md') {
          margin-right: theme($spacings, 8);
        }
      }
    }

    @include from('md') {
      margin-top: theme($spacings, 14);
    }
  }

  .cardInfos {
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: theme($spacings, 12);
    pointer-events: none;

    .cardInfosItem {
      flex: 1;
      box-sizing: border-box;
      pointer-events: none;

      & + .cardInfosItem {
        margin-top: theme($spacings, 2);
      }
    }

    @include from('md') {
      padding: 0;
      align-items: center;
      flex-direction: row;

      .cardInfosItem {
        padding: theme($spacings, 12);

        & + .cardInfosItem {
          margin-top: 0;
        }
      }
    }
  }

  .colorsProduct {
    justify-content: flex-start;
    margin-top: theme($spacings, 3);
  }

  .prices {
    display: inline-flex;
    margin-top: theme($spacings, 2);

    .compareAtPrice {
      text-decoration: line-through;
      color: theme($colors, 'dove-gray');
      margin-right: theme($spacings, 5);
    }
  }

  .pin,
  .discountBadge {
    z-index: 2;
    position: absolute;
    top: theme($spacings, 10);

    pointer-events: none;
  }

  .pin {
    right: theme($spacings, 10);
  }

  .discountBadge {
    left: theme($spacings, 10);
  }

  .ratio {
    @include applyPercentRatios(math.div(312, 416));
  }
}
